<template>
    <evento-form
        acao="ATUALIZAR"
        @salvar="atualizar($event)"
        @atualizar="obterEvento()"
        @atualizarErros="erros = $event"
        :cancelar="cancelar"
        :erros="erros"
        :evento="evento"
        :nivel="nivel"
        :eventoPaiId="eventoPaiId"
    ></evento-form>
</template>

<script>
import EventosServices from './services';
import EventoForm from './EventoForm';

export default {
    components: {
        EventoForm,
    },

    data() {
        return {
            erros: [],
            evento: null,
            atualizado: false,
        };
    },

    computed: {
        nivel() {
            return this.evento?.nivel;
        },

        eventoPaiId() {
            return this.evento?.eventoPaiId;
        },
    },

    methods: {
        cancelar() {
            if (this.atualizado) {
                this.$store.dispatch('setAtualizar', true);
            }
            this.$router.push({
                name: 'Checkins_Eventos_Detalhar',
                params: {
                    id: this.$route.params.id,
                },
            });
        },

        obterEvento() {
            this.$store.dispatch('addRequest');
            EventosServices.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.evento = response.data;
                } else {
                    this.evento = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar(eventoAtualizado) {
            eventoAtualizado.eventoId = this.evento.eventoId;
            this.$store.dispatch('addRequest');
            EventosServices.atualizar(eventoAtualizado).then((response) => {
                if (response?.success) {
                    this.evento = response.data;
                    this.atualizado = true;
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Evento',
                        detail: 'Evento atualizado com sucesso',
                        life: 3000,
                    });
                    this.cancelar();
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    mounted() {
        this.obterEvento();
    },
};
</script>
