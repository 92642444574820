<template>
    <painel titulo="Evento" icone="pi pi-calendar" :refreshFunction="atualizar" :mostrarRefresh="acao == 'ATUALIZAR'">
        <erros-box :erros="erros"></erros-box>
        <div class="formgrid grid p-fluid">
            <div class="field col-12 md:col-2">
                <label class="required">Código</label>
                <InputText type="text" v-model="codigo" @input="v$.codigo.$touch()" maxlength="100" />
                <small class="p-error" v-if="v$.codigo.$error">O código é obrigatório</small>
            </div>
            <div class="field col-12 md:col-2">
                <label class="required">Código Interno</label>
                <div class="p-inputgroup">
                    <InputNumber v-model="codigoInterno" inputId="withoutgrouping" :useGrouping="false" @input="v$.codigoInterno.$touch()" />
                    <Button icon="pi pi-search" @click="pesquisarEvento()" />
                </div>
                <small class="p-error" v-if="v$.codigoInterno.$error">O código interno é obrigatório</small>
            </div>
            <div class="field col-12 md:col-2" v-if="nivel == 0">
                <label class="required">Código Externo</label>
                <InputText type="text" v-model="codigoExterno" maxlength="100" :disabled="participantesInternos" @input="v$.codigoExterno.$touch()" />
                <small class="p-error" v-if="v$.codigoExterno.$error">O código externo é obrigatório</small>
            </div>
            <div class="field col-12" v-if="contasEventos && nivel == 0 && !participantesInternos">
                <label>Conta do Evento</label>
                <Dropdown
                    v-model="contaEventoId"
                    :options="contasEventos"
                    optionLabel="nome"
                    optionValue="contaEventoId"
                    :filter="true"
                    placeholder="Selecione a conta do evento"
                    :showClear="true"
                    :filterFields="['nome']"
                >
                </Dropdown>
            </div>
            <div class="field col-12">
                <label class="required">Nome</label>
                <InputText type="text" v-model="nome" @input="v$.nome.$touch()" maxlength="500" />
                <small class="p-error" v-if="v$.nome.$error">O nome é obrigatório</small>
            </div>
            <div class="field col-12">
                <label class="required">Descrição</label>
                <InputText type="text" v-model="descricao" @input="v$.descricao.$touch()" maxlength="1000" />
                <small class="p-error" v-if="v$.descricao.$error">A Descrição é obrigatória</small>
            </div>
            <div class="datas field col-2">
                <label>Data Inicio</label>
                <InputText type="text" v-model="dataInicioFormatada" @input="v$.codigo.$touch()" maxlength="500" disabled />
            </div>
            <div class="datas field col-2">
                <label>Data Fim</label>
                <InputText type="text" v-model="dataFimFormatada" @input="v$.codigo.$touch()" maxlength="500" disabled />
            </div>
            <div class="field field-checkbox col-12 md:col-2">
                <Checkbox v-model="geraAtendimento" :binary="true" id="geraAtendimento" />
                <label for="geraAtendimento">Gera Atendimento</label>
            </div>
            <div class="field field-checkbox col-12 md:col-2" v-if="nivel == 0">
                <Checkbox v-model="sincronizarCheckinExterno" :binary="true" id="sincronizarCheckinExterno" :disabled="participantesInternos" />
                <label for="sincronizarCheckinExterno">Sincronizar Checkin Externo</label>
            </div>
            <div class="field field-checkbox col-12 md:col-2" v-if="mostrarChkBuscarCnpj">
                <Checkbox v-model="buscarCnpj" :binary="true" id="buscarCnpj" />
                <label for="buscarCnpj">Buscar CNPJ</label>
            </div>
            <div class="field field-checkbox col-12 md:col-2">
                <Checkbox v-model="participantesInternos" :binary="true" id="participantesInternos" />
                <label for="participantesInternos">Obter Participantes do SAS</label>
            </div>
        </div>
        <slot name="botoes"></slot>
        <Button label="Cancelar" icon="pi pi-times-circle" class="p-button-secondary" @click="cancelar" />
        <Button label="Salvar" icon="pi pi-check-circle" class="ml-2 p-button-primary" @click="confirmarSalvar()" :disabled="v$.$invalid" />
    </painel>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import EventosServices from './services';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    props: {
        acao: {
            type: String,
        },

        evento: {
            type: Object,
        },

        erros: {
            type: Array,
        },

        cancelar: {
            type: Function,
        },

        nivel: {
            type: Number,
        },

        eventoPaiId: {
            type: String,
        },

        eventoPaiBuscaCnpj: {
            type: Boolean,
        },
    },

    emits: ['salvar', 'atualizar'],

    data() {
        return {
            codigoInterno: null,
            codigoExterno: null,
            descricao: null,
            dataInicio: null,
            dataFim: null,
            dataInicioFormatada: null,
            dataFimFormatada: null,
            codigo: null,
            nome: null,
            contaEventoId: null,
            geraAtendimento: false,
            sincronizarCheckinExterno: false,
            buscarCnpj: false,
            contasEventos: [],
            participantesInternos: false,
        };
    },

    validations() {
        return {
            nome: { required },
            descricao: { required },
            codigo: { required },
            codigoInterno: { required },
            codigoExterno: {
                codigoExternoOk: () => {
                    return this.codigoExternoOk;
                },
            },
            geraAtendimento: { required },
            sincronizarCheckinExterno: { required },
            buscarCnpj: { required },
        };
    },

    computed: {
        codigoExternoOk() {
            if (this.nivel == 0) {
                return this.codigoExterno != null && this.codigoExterno != '';
            }
            return true;
        },

        pbcnpj() {
            if (!this.evento) {
                return this.eventoPaiBuscaCnpj;
            }
            return this.evento.eventoPai.buscarCnpj;
        },

        mostrarChkBuscarCnpj() {
            return this.nivel == 0 || (this.nivel != 0 && !this.pbcnpj);
        },
    },

    methods: {
        obterContasEventos() {
            this.$store.dispatch('addRequest');
            EventosServices.obterContasEventos()
                .then((response) => {
                    if (response?.success) {
                        this.contasEventos = response.data;
                    } else {
                        this.contasEventos = [];
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        preencher() {
            this.codigoExterno = this.evento?.codigoExterno;
            this.codigoInterno = this.evento?.codigoInterno;
            this.descricao = this.evento?.descricao;
            this.codigo = this.evento?.codigo;
            this.nome = this.evento?.nome;
            this.geraAtendimento = this.evento?.geraAtendimento;
            this.sincronizarCheckinExterno = this.evento?.sincronizarCheckinExterno;
            this.buscarCnpj = this.evento?.buscarCnpj;
            this.contaEventoId = this.evento?.contaEventoId;
            this.participantesInternos = this.evento?.participantesInternos;
            this.v$.$touch();
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja ${this.acao.toLowerCase()} o evento?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            let eventoDto = {
                codigo: this.codigo,
                codigoInterno: this.codigoInterno,
                codigoExterno: this.codigoExterno,
                nome: this.nome,
                descricao: this.descricao,
                nivel: this.nivel,
                eventoPaiId: null,
                geraAtendimento: this.geraAtendimento,
                sincronizarCheckinExterno: this.sincronizarCheckinExterno,
                buscarCnpj: this.buscarCnpj,
                contaEventoId: this.contaEventoId,
                participantesInternos: this.participantesInternos,
                dataInicio: this.dataInicio,
                dataFim: this.dataFim,
            };

            if (this.nivel != 0) {
                eventoDto.eventoPaiId = this.eventoPaiId;
            }

            this.$emit('salvar', eventoDto);
        },

        atualizar() {
            this.$emit('atualizar');
        },

        pesquisarEvento() {
            this.$store.dispatch('addRequest');
            EventosServices.pesquisarEventoInterno(this.codigoInterno)
                .then((response) => {
                    if (response?.success) {
                        this.nome = response.data.titulo;
                        this.descricao = response.data.descricao;
                        this.dataInicioFormatada = this.$dateFormat(response.data.dataInicio, 'DD/MM/yyyy');
                        this.dataFimFormatada = this.$dateFormat(response.data.dataFim, 'DD/MM/yyyy');
                        this.dataInicio = response.data.dataInicio;
                        this.dataFim = response.data.dataFim;
                    } else {
                        this.$emit('atualizarErros', response.errors);
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },

    watch: {
        evento() {
            if (this.evento) {
                this.preencher();
            }
        },

        participantesInternos() {
            if (this.participantesInternos) {
                this.codigoExterno = '0';
                this.sincronizarCheckinExterno = 0;
                this.contaEventoId = null;
            }
        },
    },

    created() {
        this.obterContasEventos();
    },
};
</script>
